<template>
    <div class="DeviceClassForm">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <el-form :inline="true" class="demo-form-inline" :model="deviceClassForm" ref="deviceClassForm">
                    <el-form-item label="分类名称">
                        <el-input v-model="deviceClassForm.subClassDesc" placeholder="请输入分类名称查询"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="上级分类">
                        <el-select placeholder="请选择上级分类名称查询" v-model="deviceClassForm.superClassCode">
                            <el-option v-for="(item, index) in oneClassList" :key="index" :label="item.subClassDesc"
                                :value="item.subClassCode"></el-option>
                        </el-select>
                    </el-form-item> -->
                    <el-form-item>
                        <el-button  class="btn" @click="handleSearch" icon="el-icon-search">查询</el-button>
                        <el-button @click="handleReset" icon="el-icon-refresh-right">重置</el-button>
                    </el-form-item>
                </el-form>
                <div class="clearfix">
                    <el-button  class="btn" @click="$router.push({ name: 'deviceClass-create' })"
                        icon="el-icon-circle-plus-outline">新增</el-button>
                </div>
            </div>

            <!-- 列表 -->
            <el-table :data="deviceClassList" border  style="width: 100%; font-size: 13px;"
                :header-cell-style="{ background: '#eee', fontWeight: 700, color: '#606266' }">
                <el-table-column prop="seq" label="序号" align="center" width="50px">
                </el-table-column>
                <el-table-column prop="subClassCode" label="子类编码" align="center"> </el-table-column>
                <el-table-column prop="subClassDesc" label="分类名称" align="center">
                </el-table-column>
                <el-table-column prop="superClassCode" label="父类编号" align="center">
                </el-table-column>
                <el-table-column prop="level_text" label="级别" align="center">
                </el-table-column>
                <el-table-column prop="createTime" label="创建时间" align="center">
                </el-table-column>

                <el-table-column label="操作" align="center" width="180">
                    <template slot-scope="scope">
                        <el-button type="text" size="mini"  title="修改"
                            @click="handleEdit(scope.row)">修改</el-button>
                        <el-button type="text" size="mini"  title="删除" class="delColor"
                            @click="del(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>

            <!-- 分页 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page="deviceClassForm.pageNum" :page-sizes="[5, 10, 20, 50, 100]"
                :page-size="deviceClassForm.pageSize" layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>


        </el-card>
    </div>
</template>
<script>
import { serchDeviceClass, deleteDeviceClass } from '@/api/deviceClass.js'
export default {
    data() {
        return {
            deviceClassList: [],
            // oneClassList: [],
            deviceClassForm: {
                subClassDesc: '',
                // superClassCode: '',
                pageNum: 1,
                pageSize: 10
            },
            total: 0,
        }
    },
    mounted() {
        // this.loadSelect()
        this.serchDeviceClass()
    },
    methods: {
        handleSizeChange(val) {
            this.deviceClassForm.pageSize = val;
            this.deviceClassForm.pageNum = 1;
            this.serchDeviceClass();
        },
        handleCurrentChange(val) {
            this.deviceClassForm.pageNum = val;
            this.serchDeviceClass();
        },
        //查询
        serchDeviceClass() {
            serchDeviceClass(this.deviceClassForm).then(res => {
                if (res.code === '000000') {
                    this.deviceClassList = res.t.list
                    this.total = res.t.total
                    var count = 1;
                    this.deviceClassList.forEach((item) => {
                        item.seq = count++
                        item.level === 0 ? (item.level_text = '一级') : (item.level===1?item.level_text = '二级':item.level_text = '三级')
                        // count= count +1;
                    })
                }

            })

        },
        // async loadSelect() {
        //     await querySpareOneClass().then(res => {
        //         if (res.code === '000000') {
        //             this.oneClassList = res.t
        //         }
        //     })
        // },

        handleEdit({ id }) {
            this.$router.push({
                name: 'deviceClass-edit',
                params: {
                    id
                }
            })
        },

        //删除
        del({id,subClassDesc}) {
            this.$confirm('是否删除名称为“'+subClassDesc+'”的仪器分类？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                console.log(id)
                deleteDeviceClass(id).then(res => {
                    if (res.code === '000000') {
                        this.$message.success('删除成功')
                        this.serchDeviceClass()
                    }
                })
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                })
            })

        },
        handleSearch() {
            this.serchDeviceClass(this.deviceClassForm)
        },
        handleReset() {
            this.deviceClassForm = {}
            this.serchDeviceClass(this.deviceClassForm)
        }
    }
}
</script>


<style lang="scss" scoped>
.DeviceClassForm {

    .el-pagination,
    .clearfix {
        text-align: left;
    }

    .delColor {
        color: red;
    }

}
</style>